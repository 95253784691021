<script setup lang="ts">
import { isAppUsing } from '@@/bits/flip'
import { loadFontVariant } from '@@/bits/font_loading'
import { defineAsyncComponent } from '@@/bits/vue'
import { uniqueId } from 'es-toolkit/compat'
import { computed, onBeforeUpdate, onMounted, ref } from 'vue'
import InlineSvg from 'vue-inline-svg'

const OzColoredIcon = defineAsyncComponent(() => import('@@/library/v4/components/OzColoredIcon.vue'))
const OzEmojiIcon = defineAsyncComponent(() => import('@@/library/v4/components/OzEmojiIcon.vue'))

type NumberOfPaths = number
const COLORED_ICONS: Record<string, NumberOfPaths> = {
  'amazon-color': 2,
  'paypal-color': 3,
  'discover-color': 3,
  'jcb-color': 5,
  'mastercard-color': 3,
  'unionpay-color': 5,
  'amex-color': 2,
  'dinersclub-color': 3,
  'visa-color': 3,
  google: 4,
  windows: 4,
  classlink: 4,
  skoletube: 2,
  twitter: 1,
  google_drive_color: 6,
  clever_blue: 1,
  clever_white: 1,
}
// @see https://material.io/design/usability/bidirectionality.html#mirroring-elements
const ORICONS_MIRRORING = [
  // Arrows
  'back',
  'arrow_right',
  'highlight_text',
  'arrow_left',
  'arrow_right',
  'chevron_left_center',
  'chevron_left_left',
  'chevron_right_center',
  'chevron_right_right',
  // Text-related
  'attachment_caption',
  'subscript',
  'superscript',
  'bulleted_list',
  'numbered_list',
  'clipboard',
  'clipboard_outline',
  'comment',
  'quote',
  'enlarge',
  'dock',
  'expand',
  'padlets',
  'send_back',
  'send_front',
  'select_all',

  // Section-related
  'section_arrow_left',
  'section_arrow_right',
  // Movement
  'transfer',
  'external_link',
  'external_link_outline',
  'grid_link',
  'new_frame_above',
  'new_frame_below',
  'duplicate_outline',
  'clear_frame',
  'copy_outline',
  'scissors',
  'send_to_front_outline',
  'send_to_back_outline',

  // Passage of time
  'undo',
  'redo',
  // Others
  'note_filled',
  'note_outline',
]
const WBICONS_MIRRORING = [
  // Text-related/Movement
  'highlighter',
  'eraser',
  // Passage of time
  'undo',
  'redo',
  // Others
  'postnote',
]
/**
 * For list of icons:
 *
 *  - Go to https://icomoon.io/app/#/projects
 *  - Load appropriate project (*Immaterial* or *Oricons*)
 *  - Click *Generate Font* at the bottom
 *
 */

type IconSize = 'small' | 'normal' | 'large'

const props = withDefaults(
  defineProps<{
    /**
     * If dir="rtl" is included, left-right arrows buttons are flipped
     * @default ltr
     */
    dir?: 'ltr' | 'rtl' | 'auto'
    /**
     * Name of the icon to load
     */
    name: string
    /**
     * Determines the size of the icon
     */
    size?: number | IconSize
    /**
     * Determines if icon should be hidden from screen reader (i.e. only decorative). Default is true.
     */
    ariaHidden?: boolean
    /**
     * Determines the icon font to load
     */
    font?: 'immaterial' | 'oricon' | 'wbicons'
    /**
     * Used to force-refresh SVG icons fetched from our CDN.
     * Change this value to bypass CDN caching and fetch the latest SVG.
     */
    svgVersionKey?: string
  }>(),
  {
    dir: 'auto',
    size: 'normal',
    ariaHidden: true,
    font: 'oricon',
    svgVersionKey: '2',
  },
)

defineEmits<{
  (name: 'click', event: MouseEvent): void
}>()

const isFontLoaded = ref(false)

const isColoredIcon = computed((): boolean => Object.keys(COLORED_ICONS).includes(props.name))

const isMirroringEnabled = computed((): boolean => {
  if (!props.name) return false
  return (
    (props.font === 'oricon' && ORICONS_MIRRORING.includes(props.name)) ||
    (props.font === 'wbicons' && WBICONS_MIRRORING.includes(props.name))
  )
})

// @see https://material.io/design/usability/bidirectionality.html#mirroring-elements
//
// Use `inline-block` to force the element to be transformable:
// https://stackoverflow.com/questions/14883250/css-transform-doesnt-work-on-inline-elements
const rtlTransformClasses = computed((): string => {
  if (!isMirroringEnabled.value) return ''
  if (props.dir === 'rtl') return 'inline-block transform -scale-x-100'
  if (props.dir === 'auto') return 'inline-block rtl:transform rtl:-scale-x-100'
  return ''
})

const numOfPaths = computed((): number => COLORED_ICONS[props.name])

const iconClass = computed((): string => {
  if (props.font === 'immaterial') {
    return 'immaterial-icons'
  } else if (props.font === 'wbicons') {
    return 'wbicons'
  }
  return 'oricon'
})

const iconSize = computed((): number => {
  let sizeNumber = 24
  if (typeof props.size === 'number') {
    sizeNumber = props.size
  } else if (props.size === 'small') {
    sizeNumber = 18
  } else if (props.size === 'large') {
    sizeNumber = 32
  }
  return sizeNumber
})

const styles = computed((): string => {
  const sizeNumber = iconSize.value
  if (!isFontLoaded.value) {
    return `visibility: hidden; font-size: ${sizeNumber}px; min-width:${sizeNumber}px; max-width:${sizeNumber}px; width: ${sizeNumber}px; height: ${sizeNumber}px;`
  }

  return `font-size: ${sizeNumber}px; max-width:${sizeNumber}px; width: ${sizeNumber}px; height: ${sizeNumber}px; overflow: visible;`
})

const svgCustomClasses = computed((): string => {
  if (props.name === 'padlet_outline') {
    // There’s an issue where exporting the correct stroke width for the Padlet logo specifically breaks the svg, so the exported value is thinner than it should be, and we manually override it here.
    return `stroke-current stroke-[0.5px]`
  }
  return ''
})

const isEmoji = computed((): boolean => {
  // eslint-disable-next-line prefer-regex-literals
  const emojiRegex = new RegExp(
    '^(\\p{RI}\\p{RI}|\\p{Extended_Pictographic}(\\p{Emoji_Modifier}|\\u{FE0F}\\u{20E3}?|[\\u{E0020}-\\u{E007E}]+\\u{E007F})?(\\u{200D}(\\p{RI}\\p{RI}|\\p{Emoji}(\\p{Emoji_Modifier}|\\u{FE0F}\\u{20E3}?|[\\u{E0020}-\\u{E007E}]+\\u{E007F})?))*)',
    'u',
  )

  return props.name.match(emojiRegex) !== null
})

const inlineSvgUrl = computed((): string => {
  const baseUrl = 'https://padlet.net/icons/svg'
  const iconType = isAppUsing('sandboxSvgIcons') && props.font === 'wbicons' ? 'wbicons' : 'oricons'
  const versionSuffix = props.svgVersionKey ? `?v=${props.svgVersionKey}` : ''

  return `${baseUrl}/${iconType}/${props.name}.svg${versionSuffix}`
})

/**
 * A race condiiton could be generated in `InlineSvg`
 * causing icons not to update accordingly.
 * We use keys to force a replacement.
 */
const inlineSvgKey = ref<string>(uniqueId('icon-'))

onBeforeUpdate(() => {
  inlineSvgKey.value = uniqueId('icon-')
})

onMounted(() => {
  const fontVariant = `24px ${props.font}`
  loadFontVariant(fontVariant).then((isLoaded) => {
    isFontLoaded.value = isLoaded
  })
})
</script>

<script lang="ts">
export default {}
</script>

<template>
  <OzColoredIcon
    v-if="isColoredIcon"
    :class="[
      iconClass,
      `icon-${name}`,
      isMirroringEnabled && {
        'inline-block -scale-x-100': dir === 'rtl',
        'inline-block rtl:-scale-x-100': dir === 'auto',
      },
    ]"
    :style="styles"
    :num-of-paths="numOfPaths"
    :aria-hidden="String(ariaHidden)"
  />
  <OzEmojiIcon
    v-else-if="isEmoji"
    :class="[
      iconClass,
      isMirroringEnabled && {
        'inline-block -scale-x-100': dir === 'rtl',
        'inline-block rtl:-scale-x-100': dir === 'auto',
      },
    ]"
    :name="name"
    :style="styles"
    :aria-hidden="String(ariaHidden)"
  />
  <i
    v-else-if="!isAppUsing('sandboxSvgIcons') && iconClass === 'wbicons'"
    :class="[iconClass, rtlTransformClasses]"
    :style="styles"
    :aria-hidden="String(ariaHidden)"
    @click="$emit('click', $event)"
    >{{ name }}</i
  >
  <span v-else class="flex items-center justify-center" :style="`width: ${iconSize}px; height: ${iconSize}px`">
    <InlineSvg
      :key="inlineSvgKey"
      :class="['fill-current', rtlTransformClasses, svgCustomClasses]"
      style="overflow: visible"
      :src="inlineSvgUrl"
      :width="iconSize"
      :height="iconSize"
      :aria-hidden="String(ariaHidden)"
      alt=""
      @click="$emit('click', $event)"
    />
  </span>
</template>

<style lang="scss" scoped>
@import '@@/fonts/wbicons';

.wbicons {
  @include wbicons;
}
</style>
