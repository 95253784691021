// @file Functions related to tracking font loading with browser's document.font
// Font variant string is an accepted input of document.fonts.load. e.g. "16px Arial"

const fontVariantPromises: Record<string, Promise<boolean>> = {}

async function buildFontVariantPromise(fontVariant: string): Promise<boolean> {
  if (document.fonts) {
    const previouslyLoaded = await document.fonts.check(fontVariant)
    if (previouslyLoaded) return true

    const fontsLoaded = await document.fonts.load(fontVariant)
    return fontsLoaded.length > 0
  }

  // If FontFaceSet API is not supported, we fallback to DOM ready state
  // The `DOMContentLoaded` event handler won't trigger if the document has already loaded.
  if (document.readyState === 'complete' || document.readyState === 'interactive') {
    return true
  }
  return await new Promise<boolean>((resolve) => {
    window.addEventListener('DOMContentLoaded', () => resolve(true))
  })
}

// Use this to wait for font variant loading to complete before showing text/icons.
export async function loadFontVariant(fontVariant: string): Promise<boolean> {
  if (fontVariantPromises[fontVariant] === undefined) {
    fontVariantPromises[fontVariant] = buildFontVariantPromise(fontVariant)
  }

  return await fontVariantPromises[fontVariant]
}
